import tw, { styled } from "twin.macro"
import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import parse from "html-react-parser"

const FooterContainer = styled.div`
  ${tw`flex w-full pt-28 lg:pt-40 pb-c96 2xl:pb-c120 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const FooterRevealer = styled.div`
  ${tw`w-full`}
`
const FooterParagraph = styled.p`
  ${tw`mb-4 text-base font-bold text-right uppercase font-ubuntu text-customGrey md:text-xl`}
`

const Nav = styled.nav`
  ${tw`w-10/12 md:w-[220px]`}
  /* width: 220px; */
  & > div {
    ${tw`flex flex-col w-full font-bold text-right uppercase text-lightGrey font-ubuntu text-16`}

    & a {
      ${tw`relative pb-1`}

      &::after {
        content: "|->";
        ${tw`absolute font-bold transform translate-x-full -right-2 font-firacode`}
      }
    }
  }
`

function MenuFooter() {
  const footerRef = React.useRef(null)

  // body
  const data = useStaticQuery(graphql`
    query FooterMenueSeo {
      allWpMenu(filter: { slug: { regex: "/^menu-/" } }) {
        edges {
          node {
            menuItems {
              nodes {
                id
                path
                label
              }
            }
            name
            slug
          }
        }
      }
      wpPage(title: { eq: "Home" }) {
        id
        acfFooterMenu {
          menu1Title
          menu2Title
          menu3Title
          menu4Title
        }
      }
    }
  `)
  // const menuItems = data.wpMenu.menuItems.nodes.map(item => item)
  const menuTitlesArray = Object.values(data.wpPage.acfFooterMenu)
  if (data.allWpMenu.edges.every(menu => menu.node.menuItems?.nodes.length < 1))
    return null
  const fetchedData = data.allWpMenu.edges
  const extractNumber = slug => parseInt(slug.replace(/^\D+/g, ""), 10)
  const sortedData = fetchedData.sort((a, b) => {
    return extractNumber(a.node.slug) - extractNumber(b.node.slug)
  })

  return (
    <>
      <FooterRevealer className={`overflow-hidden`}>
        <FooterContainer
          ref={footerRef}
          className="flex flex-wrap gap-20 bg-white md:gap-40"
        >
          {sortedData.map((menu, index) => {
            if (menu.node.menuItems?.nodes.length > 0) {
              return (
                <Nav key={index}>
                  {menuTitlesArray[index] && (
                    <FooterParagraph>
                      {parse(menuTitlesArray[index])}
                    </FooterParagraph>
                  )}
                  <div>
                    {menu.node.menuItems.nodes.map(({ path, label, id }) => (
                      <Link
                        key={`${label}-${id}`}
                        to={path}
                        title={label}
                        aria-label={label}
                      >
                        {label}
                      </Link>
                    ))}
                  </div>
                </Nav>
              )
            }
            return null // Return null if there are no menu items
          })}
        </FooterContainer>
      </FooterRevealer>
    </>
  )
}

export default MenuFooter
